<template>
  <div class="seal">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-button type="primary" @click="handleShow('add')">新增</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column label="签章">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.signatureUrl)">查看图片</span>
            </template>
          </el-table-column>
          <el-table-column prop="signatureTypeName" label="签章类型">
            <!-- <template slot-scope="scope">{{ selectedValue(scope.row.signatureType) }}</template> -->
          </el-table-column>
          <el-table-column prop="updatedTime" label="修改日期"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="160">
            <template slot-scope="scope">
              <!-- <el-button class="btn" type="primary" plain @click="handleEdit(scope.row)">修改</el-button> -->
              <el-button type="danger" plain @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog :title="isAdd ? '新增' : '修改'" :visible.sync="dialogVisible" width="800px" :before-close="onCancel">
      <div class="dialog_wrap">
        <el-form class="form" ref="form" :model="form" :rules="rules" label-width="170px">
          <el-form-item label="签章类型" prop="signatureType">
            <el-select v-model="form.signatureType" placeholder="请选择">
              <el-option v-for="(item, index) in options" :key="index" :label="item.name" :value="item.code" :disabled="item.disable == 1 ? false : true"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主文字(公司名称)" prop="mainText">
            <el-input v-model="form.mainText" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="副文字" prop="">
            <el-input v-model="form.subText" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="防伪码" prop="">
            <el-input v-model="form.signCode" placeholder="请输入内容" maxlength="13" show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <div class="pic">
          <div class="demo">
            <el-image style="width: 300px; height: 300px" :src="demo" fit="contain"></el-image>
          </div>
          <div class="my">
            <el-image style="width: 240px; height: 240px" :src="form.signatureUrl" fit="contain">
              <div slot="error" class="signatureurl_slot">待生成</div>
            </el-image>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" plain @click="onGenerate">生 成</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </span>
    </el-dialog>

    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    ></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      loading: false,
      tableData: [],
      fileList: [],
      options: [],
      demo: require("@/assets/img/demo.png"),

      form: {
        signatureUrl: "",
        signatureType: "",
        mainText: "",
        subText: "",
        signCode: "",
      },
      rules: {
        signatureType: [{ required: true, message: "请选择签章类型", trigger: "change" }],
        mainText: [{ required: true, message: "请输入主文字", trigger: "blur" }],
      },
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      isAdd: false,
      dialogVisible: false,

      showViewer: false, // 图片预览
      imgList: [],

      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getList()
  },
  computed: {
    selectedValue() {
      return function (val) {
        const selectedOption = this.options.find(e => e.code == val)
        return selectedOption ? selectedOption.name : ""
      }
    },
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.selectSignatureInfoList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
            this.loading = false
            this.getOptionsList()
          } else {
            this.loading = false
          }
        })
    },
    getOptionsList() {
      this.$axios.get(this.$api.selectSignatureTypeList).then(res => {
        if (res.data.code == 100) {
          this.options = res.data.result
        }
      })
    },
    handleShow(str) {
      this.isAdd = str == "add" ? true : false
      this.dialogVisible = true
    },
    onCancel() {
      this.form = {
        signatureUrl: "",
        signatureType: "",
      }
      this.fileList = []
      this.resetForm()
      this.dialogVisible = false
    },
    // 删除图片
    handleRemove() {
      this.form.signatureUrl = ""
      this.fileList = []
    },
    // 超过最大上传数
    onExceed(fileList) {
      if (fileList.length >= 1) {
        this.$message.error("最多只能上传一张图片")
      }
    },
    // 图片上传成功
    onSuccess(res) {
      if (res.code == 100) {
        this.form.signatureUrl = res.result.fileUrl
        // this.form.signatureUrl = res.result.fileName;
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 文件上传
    beforeUploads(file) {
      const isJPG = file.type === "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图片只支持png格式，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
    handleDel(row) {
      this.$confirm("此操作将永久删除该签章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .get(this.$api.deleteSignatureInfo, {
              params: {
                id: row.id,
              },
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success("删除成功")
                this.getList()
              }
            })
        })
        .catch(() => {})
    },
    handleEdit(row) {
      this.form = Object.assign({}, row)
      this.dialogVisible = true
    },
    onGenerate() {
      if (this.form.mainText) {
        this.$axios
          .post(this.$api.createSignature, {
            mainText: this.form.mainText,
            subText: this.form.subText,
            signCode: this.form.signCode,
          })
          .then(res => {
            if (res.data.code == 100) {
              this.form.signatureUrl = res.data.result
            }
          })
      } else {
        this.$message.error("请输入主文字")
      }
    },
    submitForm(formName) {
      let params = Object.assign({}, this.form)
      let api = ""
      this.$refs[formName].validate(valid => {
        if (valid && this.form.signatureUrl != "") {
          if (this.isAdd) {
            api = this.$api.insertSignatureInfo
            params.bizId = this.$store.state.userInfo.supplierId // 业务id（总后台默认0）
            params.bizType = 3 // 类型：1商超；2供应商；3总后台
          } else {
            api = this.$api.updateSignatureInfo
          }
          this.$axios.post(api, params).then(res => {
            if (res.data.code == 100) {
              this.$message.success("提交成功")
              this.resetForm()
              this.dialogVisible = false
              this.getList()
            }
          })
        } else {
          this.$message.error("提交失败，内容不完整")
          return false
        }
      })
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getSysUserList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSysUserList()
    },
    // 图片预览
    previewImage(url) {
      this.imgList[0] = url
      this.showViewer = true
    },
    // 重置校验
    resetForm() {
      this.form = {
        signatureUrl: "",
        signatureType: "",
      }
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style scoped lang="scss">
.seal {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
  .dialog_wrap {
    display: flex;
    flex-direction: column;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ::v-deep .el-input__count-inner {
        background-color: transparent;
      }
    }
    .pic {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ::v-deep .signatureurl_slot {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 240px;
        background-color: #fff;
        color: #999;
        font-size: 18px;
        border-radius: 50%;
      }
    }
  }
}
</style>
